<template>
  <div class="myBox">
    <div class="title">{{ title }}</div>
    <div
      class="content appointedBy"
      v-if="['联系人', '紧急联系人'].includes(type)"
    >
      <div v-for="(item, index) in appointedByList" :key="index">
        <p>
          姓名：{{ item.name }}
          <span class="red" v-if="type === '紧急联系人'">(不参与)</span>
        </p>
        <p>证件类型：{{ item.documentTypeText }}</p>
        <p>证件号码：{{ item.documentNumber }}</p>
        <p>手机号：{{ item.phone }}</p>
        <template v-if="item.status === 1 && item.checkTime">
          <p>核销时间：{{ item.checkTime }}</p>
        </template>
      </div>
    </div>
    <div class="content accompanying" v-else-if="type === '随行人员'">
      <div
        v-for="(item, index) in accompanyingList"
        :key="index"
        class="accompanying_item"
      >
        <div>
          <span>{{ title }}{{ index + 1 }}</span>
          <van-button
            color="#fd4d4f"
            v-if="[0].includes(item.status) && status === 0 && isRefund"
            plain
            size="mini"
            @click="onCancel(item)"
            >退票</van-button
          >
          <span v-if="[2].includes(item.status)" class="refundTicket"
            >退票成功</span
          >
        </div>
        <p>姓名：{{ item.name }}</p>
        <p>证件类型：{{ item.documentTypeText }}</p>
        <p>证件号码：{{ item.documentNumber }}</p>
        <p>手机号：{{ item.phone }}</p>
        <template v-if="item.status === 2">
          <p>退票时间：{{ item.refundTime }}</p>
        </template>
        <template v-if="item.status === 1">
          <p>核销时间：{{ item.checkTime }}</p>
        </template>
      </div>
    </div>
    <div class="content appointedBy" v-if="type === '影票联系人'">
      <div v-for="(item, index) in appointedByList" :key="index">
        <p>姓名：{{ item.contactsName }}</p>
        <p>证件类型：{{ item.contactsIdcardType }}</p>
        <p>证件号码：{{ item.contactsIdcard }}</p>
        <p>手机号：{{ item.contactsPhone }}</p>
        <template v-if="item.isUse">
          <p>核销时间：{{ item.useTime }}</p>
        </template>
      </div>
    </div>
    <div class="content accompanying" v-else-if="type === '影票随行人员'">
      <div
        v-for="(item, index) in accompanyingList"
        :key="index"
        class="accompanying_item"
      >
        <div>
          <span>{{ title }}{{ index + 1 }}</span>
          <span v-if="item.isRefund" class="refundTicket">退票成功</span>
        </div>
        <p>姓名：{{ item.contactsName }}</p>
        <p>证件类型：{{ item.contactsIdcardType }}</p>
        <p>证件号码：{{ item.contactsIdcard }}</p>
        <p>手机号：{{ item.contactsPhone }}</p>
        <template v-if="item.isRefund">
          <p>退票时间：{{ item.refundTime }}</p>
          <p>退票金额：￥{{ item.price }}</p>
        </template>
        <template v-if="item.isUse">
          <p>核销时间：{{ item.useTime }}</p>
        </template>
      </div>
    </div>
    <div class="content accompanying" v-else-if="type === '参与者'">
      <div
        v-for="(item, index) in accompanyingList"
        :key="index"
        class="accompanying_item"
      >
        <div>
          <span>用户{{ index + 1 }}</span>
        </div>
        <p>姓名：{{ item.name }}</p>
        <p>证件类型：{{ item.documentTypeText }}</p>
        <p>证件号码：{{ item.documentNumber }}</p>
        <p>手机号：{{ item.phone }}</p>
        <p v-if="field.includes('年龄') && item.documentType !== 1">
          年龄：{{ item.age }}
        </p>
        <p v-if="field.includes('性别') && item.documentType !== 1">
          性别：{{ item.sex ? '女' : '男' }}
        </p>
        <p v-if="field.includes('年级段') && item.ageGroup">
          年级段：{{ item.ageGroup }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    title: {
      type: String,
      default: () => '',
    },
    type: {
      type: String,
      default: () => '',
    },
    status: {
      type: Number,
      default: () => null,
    },
    appointedByList: {
      type: Array,
      default: () => [],
    },
    accompanyingList: {
      type: Array,
      default: () => [],
    },
    field: {
      type: Array,
      default: () => [],
    },
    isRefund: {
      type: Boolean,
      default: () => true,
    },
  },
  created() {},
  mounted() {},
  methods: {
    onCancel(item) {
      this.$emit('onCancel', item);
    },
  },
};
</script>
<style lang="scss" scoped>
.myBox {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  background: #fff;
  .title {
    border-radius: 5px 0 0 5px;
    min-width: 32px;
    background: linear-gradient(
      180deg,
      rgba(11, 108, 249, 1) 1%,
      rgba(91, 183, 255, 1) 100%
    );
    font-weight: 650;
    font-size: 14px;
    color: #ffffff;
    text-align: center;
    writing-mode: tb-rl;
    line-height: 32px;
    letter-spacing: 8px;
    padding: 20px 0;
  }
  .content {
    width: 100%;
    padding: 20px;
  }
  .refundTicket {
    font-size: 12px;
    color: #4bd863;
  }
  .canceled {
    font-size: 12px;
    color: #fd4d4f;
  }
  .appointedBy {
    position: relative;
    overflow: hidden;
    p {
      font-size: 14px;
      color: #666;
      line-height: 24px;
      .red {
        font-size: 14px;
        color: #fd4d4f;
      }
    }
    .van-button,
    .refundTicket,
    .canceled {
      position: absolute;
      right: 20px;
      top: 20px;
    }
  }
  .accompanying {
    display: flex;
    flex-direction: column;
    .accompanying_item {
      > div {
        margin: 10px 0;
        padding-bottom: 10px;
        border-bottom: 1px solid #ececec;
        font-weight: 500;
        color: #333333;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      p {
        font-weight: 400;
        font-size: 14px;
        color: #666666;
        line-height: 24px;
      }
    }
    .accompanying_item + .accompanying_item {
      margin-top: 10px;
    }
  }
}
</style>
